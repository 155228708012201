<template>
  <ul class="menu-nav">
    <!-- <router-link to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link> -->

    <!---------------
      VENTE
    ----------------->
    <li class="menu-section">
      <h4 class="menu-text">Vente</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link v-if="hasUserRights([{ section: 'treatments', rights: ['read'] }])" to="/treatments" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-clipboard"></i>
          <span class="menu-text">Prises en charge</span>
        </a>
      </li>
    </router-link>

    <router-link v-if="hasUserRights([{ section: 'sales', rights: ['read'] }])" to="/sales" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-shopping-cart"></i>
          <span class="menu-text">Ventes</span>
        </a>
      </li>
    </router-link>

    <router-link v-if="hasUserRights([{ section: 'sales', rights: ['read'] }])" to="/quotations" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-file-contract"></i>
          <span class="menu-text">Devis</span>
        </a>
      </li>
    </router-link>

    <router-link v-if="hasUserRights([{ section: 'invoices', rights: ['read'] }])" to="/invoices" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-file-invoice"></i>
          <span class="menu-text">Factures</span>
        </a>
      </li>
    </router-link>
    
    <router-link v-if="hasUserRights([{ section: 'customers', rights: ['read'] }])" to="/customers" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-users"></i>
          <span class="menu-text">Clients</span>
        </a>
      </li>
    </router-link>


    <!---------------
      GESTION
    ----------------->
    <li class="menu-section">
      <h4 class="menu-text">Gestion</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      v-if="hasUserRights([{ section: 'devices', rights: ['read'] }])"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-laptop"></i>
        <span class="menu-text">Appareils</span>
        <i class="menu-arrow"></i>
      </a>

      <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Appareils</span>
              </span>
            </li>

            <router-link to="/devices/types" v-slot="{ href, navigate, isActive, isExactActive }">
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <span class="menu-text">Types</span>
                </a>
              </li>
            </router-link>

            <router-link to="/devices/brands" v-slot="{ href, navigate, isActive, isExactActive }">
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <span class="menu-text">Marques</span>
                </a>
              </li>
            </router-link>

            <router-link to="/devices/categories" v-slot="{ href, navigate, isActive, isExactActive }">
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <span class="menu-text">Catégories</span>
                </a>
              </li>
            </router-link>

            <router-link to="/devices/models" v-slot="{ href, navigate, isActive, isExactActive }">
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <span class="menu-text">Modèles</span>
                </a>
              </li>
            </router-link>
          </ul>
      </div>
    </li>
    
    <li
      v-if="hasUserRights([{ section: 'products', rights: ['read'] }])"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon fa fa-tags"></i>
        <span class="menu-text">Produits</span>
        <i class="menu-arrow"></i>
      </a>

      <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Produits</span>
              </span>
            </li>

            <router-link to="/products/families" v-slot="{ href, navigate, isActive, isExactActive }">
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <span class="menu-text">Familles de produits</span>
                </a>
              </li>
            </router-link>

            <router-link to="/products" v-slot="{ href, navigate, isActive, isExactActive }">
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <span class="menu-text">Produits</span>
                </a>
              </li>
            </router-link>
          </ul>
      </div>
    </li>

    <router-link v-if="hasUserRights([{ section: 'stockEntries', rights: ['read'] }])" to="/stocks" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-box-open"></i>
          <span class="menu-text">Entrées stocks</span>
        </a>
      </li>
    </router-link>
    <router-link v-if="hasUserRights([{ section: 'services', rights: ['read'] }])" to="/services" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-cut"></i>
          <span class="menu-text">Prestations</span>
        </a>
      </li>
    </router-link>


    <!---------------
      ADMINISTRATION
    ----------------->
    <li
      class="menu-section"
      v-if="
        hasUserRights([{ section: 'company', rights: ['read'] }])
        || hasUserRights([{ section: 'users', rights: ['read'] }])
        || hasUserRights([{ section: 'roles', rights: ['read'] }])
      "
    >
      <h4 class="menu-text">Administration</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    
    <router-link v-if="hasUserRights([{ section: 'company', rights: ['read'] }])" to="/company" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-store"></i>
          <span class="menu-text">Entreprise / Boutique</span>
        </a>
      </li>
    </router-link>
    
    <router-link v-if="hasUserRights([{ section: 'users', rights: ['read'] }])" to="/users" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-users-cog"></i>
          <span class="menu-text">Utilisateurs</span>
        </a>
      </li>
    </router-link>

    
    <router-link v-if="hasUserRights([{ section: 'roles', rights: ['read'] }])" to="/roles" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-shield-alt"></i>
          <span class="menu-text">Rôles</span>
        </a>
      </li>
    </router-link>

  </ul>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "KTMenu",
  computed: {
    ...mapGetters(["hasUserRights"]),
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
