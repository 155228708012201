<template>
  <!-- begin::Page loader -->
  <div class="page-loader page-loader-logo">
    <img alt="Logo" :src="logo" width="100" />
    <div class="spinner" v-bind:class="spinnerClass || 'spinner-primary'"></div>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
export default {
  name: "Loader",
  props: {
    logo: String,
    spinnerClass: String
  }
};
</script>

<style lang="scss" scoped>
.page-loader-logo {
  .spinner {
    margin-left: 1.8rem; // Décalage logo Refectio

    &.spinner-primary:before {
      border: 2px solid #6900ff;
      border-right: 2px solid transparent;
    }
  }
}
</style>