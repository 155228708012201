<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Information Banners -->
          <div
            style="margin: -25px 0 25px 0;"
          >
            <b-alert
              v-if="trialAlertPaymentMethod"
              v-model="alertBanner1" variant="info" dismissible
              class="mb-1"
            >
             <h4 class="alert-heading">Ajoutez un moyen de paiement</h4>
              Votre période d'essai se terminera le {{ new Date(currentUser.company.subscription.trialEnd).toLocaleDateString() }}.
              Pensez à configurer un <router-link to="/company" class="banner-link">moyen de paiement</router-link> afin d'éviter toute interruption de service.
            </b-alert>
            <b-alert
              v-if="remainingTasksToValidateAccountAlert.length"
              v-model="alertBanner2" variant="info" dismissible
              class="mb-1"
            >
            <h4 class="alert-heading">Validez votre compte</h4>
              Afin de valider votre compte, veuillez effectuer les actions suivantes depuis l'espace <router-link to="/company" class="banner-link">mes paramètres</router-link> : {{ remainingTasksToValidateAccountAlert.join(', ') }}.
            </b-alert>
          </div>
          <!-- end:: Information Banners -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <!-- <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar> -->
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
// import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    // KTStickyToolbar,
    KTScrollTop,
    Loader
  },
  data() {
    return {
      alertBanner1: true,
      alertBanner2: true,
    };
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login", query: { redirect: window.location.pathname + window.location.search } });
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 500);
  },
  methods: {},
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "currentUser",
      "hasUserRights",
    ]),

    trialAlertPaymentMethod() {
      return this.currentUser.company.subscription.status === 'trialing'
              && !this.currentUser.company.lastPaymentMethodAttached
              && this.hasUserRights([{ section: 'company', rights: ['read'] }]);
    },

    remainingTasksToValidateAccountAlert() {
      const tasks = [];
      if (this.hasUserRights([{ section: 'company', rights: ['read'] }])) {
        if (!this.currentUser.company.cgsFilename) {
          tasks.push('Accepter les CGS qui vous ont été envoyées par email');
        }
        if (!this.currentUser.company.kbisFilename) {
          tasks.push('Téléverser votre extrait de Kbis');
        }
        if (!this.currentUser.company.idCardFilename) {
          tasks.push("Téléverser votre pièce d'identité");
        }
      }
      return tasks;
    },

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  }
};
</script>

<style lang="scss">
.banner-link {
  color: lighten(#8950FC, 90%) !important;
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    text-decoration: underline !important;
  }
}  
</style>
