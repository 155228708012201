<template>
  <div
    class="subheader py-2 py-lg-4 subheader-solid"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-center flex-wrap mr-1">
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          {{ title }}
        </h5>
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
        >
          <li class="breadcrumb-item">
            <router-link :to="'/'" class="subheader-breadcrumbs-home">
              <i class="flaticon2-shelter text-muted icon-1x"></i>
            </router-link>
          </li>

          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li class="breadcrumb-item" :key="`${i}-${breadcrumb.id}`">
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
                class="text-muted"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span class="text-muted" :key="i" v-if="!breadcrumb.route">
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
      </div>
      <div class="d-flex align-items-center">
        <b-dropdown
          size="sm"
          variant="link"
          toggle-class="custom-v-dropdown"
          no-caret
          right
          no-flip
        >
          <template v-slot:button-content>
            <div
              class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <span
                class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
              >
                Bonjour,
              </span>
              <span
                class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
              >
                {{ currentUser.firstname }}
              </span>
              <span class="symbol symbol-35">
                <img v-if="false" alt="Pic" :src="currentUserPersonalInfo.photo" />
                <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
                  {{ currentUser.firstname.charAt(0).toUpperCase() }}
                </span>
              </span>
            </div>
          </template>
          <!--begin::Navigation-->
          <div class="navi navi-hover min-w-md-200px">
            <!-- <b-dropdown-text tag="div" class="navi-item">
              <a href="#" class="navi-link">
                <span class="navi-icon">
                  <i class="fas fa-user-cog mr-3" />
                </span>
                <span class="navi-text">Paramètres</span>
              </a>
            </b-dropdown-text> -->
            <b-dropdown-text tag="div" class="navi-item">
              <a href="#" @click.prevent="onLogout" class="navi-link">
                <span class="navi-icon">
                  <i class="fas fa-power-off mr-4" />
                </span>
                <span class="navi-text">Déconnexion</span>
              </a>
            </b-dropdown-text>
          </div>
          <!--end::Navigation-->
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "currentUser", "hasUserRights"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    }
  },
};
</script>
